// import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import { IoShareSocialOutline, IoCopyOutline } from "react-icons/io5";
// import {
//   FaFacebook,
//   FaTwitterSquare,
//   FaLinkedin,
//   FaWhatsappSquare,
// } from "react-icons/fa";
// import { toast } from "react-toastify";

// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   LinkedinShareButton,
//   WhatsappShareButton,
// } from "react-share";

// const ShareButton = (props) => {
//   const { id } = useParams();
//   console.log(props);
//   let shareUrl;
//   let title;

//   if (props.Lesson_data) {
//     const { lesson_id, lesson_title } = props.Lesson_data;
//     shareUrl = `https://ilearnings.in/less/${lesson_id}`;
//     title = `${lesson_title}`;
//   }

//   if (props.Tutorial_data && Array.isArray(props.Tutorial_data)) {
//     props.Tutorial_data.forEach(({ tutorial_series_name }) => {
//       if (tutorial_series_name) {
//         shareUrl = `https://ilearnings.in/tutorial/${encodeURIComponent(
//           tutorial_series_name
//         )}`;
//         title = `${tutorial_series_name}`;
//       }
//     });
//   }

//   if (props.Read_more && Array.isArray(props.Read_more)) {
//     props.Read_more.forEach(({ post_id, post_title }) => {
//       if (post_id) {
//         shareUrl = `https://ilearnings.in/read/${id}`;
//         title = `${post_title}`;
//       }
//     });
//   }

//   // const imageUrl = `https://ilearnings.in/logo1.png`;
//   //  const shareUrl = "https://xteamindia.com/";
//   //  const title = "send Title"
//   // const shareUrl = "https://ilearnings.in/";
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };
//   const handleCopy = () => {
//     navigator.clipboard
//       .writeText(shareUrl)
//       .then(() => {
//         toast.success("Link copied to successfully!", {
//           position: "top-center",
//         });
//       })
//       .catch((err) => {
//         console.error("Failed to copy: ", err);
//         toast.error("Failed to copy the link!", {});
//       });
//   };

//   return (
//     <div className="dropdown">
//       <span
//         onClick={toggleDropdown}
//         className="text-light"
//         id="share-button"
//         aria-haspopup="true"
//         aria-expanded={isDropdownOpen}
//         data-bs-toggle="dropdown"
//       >
//         <IoShareSocialOutline />
//       </span>
//       <div
//         className={`dropdown-menu dropdown-menu-end ${
//           isDropdownOpen ? "show" : ""
//         }`}
//         aria-labelledby="share-button"
//       >
//         <div className="dropdown-item" onClick={handleCopy}>
//           <IoCopyOutline /> Copy Link
//         </div>
//         <div className="dropdown-item">
//           <FacebookShareButton
//             url={shareUrl}
//             quote={title}
//             hashtag="#Learning"
//             // image={imageUrl}
//             round={true}
//           >
//             <FaFacebook /> Facebook
//           </FacebookShareButton>
//         </div>
//         <div className="dropdown-item">
//           <TwitterShareButton url={shareUrl} title={title} round={true}>
//             <FaTwitterSquare /> Twitter
//           </TwitterShareButton>
//         </div>
//         <div className="dropdown-item">
//           <LinkedinShareButton
//             url={shareUrl}
//             title={title}
//             summary={title}
//             // source={imageUrl}
//             round={true}
//           >
//             <FaLinkedin /> LinkedIn
//           </LinkedinShareButton>
//         </div>
//         <div className="dropdown-item">
//           <WhatsappShareButton
//             url={shareUrl}
//             title={title}
//             separator={`\n`}
//             // media={imageUrl}
//             round={true}
//           >
//             <FaWhatsappSquare /> WhatsApp
//           </WhatsappShareButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ShareButton;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { IoShareSocialOutline, IoCopyOutline } from "react-icons/io5";
import {
  FaFacebook,
  FaTwitterSquare,
  FaLinkedin,
  FaWhatsappSquare,
} from "react-icons/fa";
import { toast } from "react-toastify";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

const ShareButton = (props) => {
  const { id } = useParams();

  const toSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  console.log(props);
  let shareUrl;
  let title;
  let imageUrl = "https://ilearnings.in/logo1.png"; // Default Image

  if (props.Lesson_data) {
    const { lesson_id, lesson_title } = props.Lesson_data;
    shareUrl = `https://ilearnings.in/less/${lesson_id}`;
    title = `${lesson_title}`;
  }

  if (props.Tutorial_data && Array.isArray(props.Tutorial_data)) {
    props.Tutorial_data.forEach(({ tutorial_series_name }) => {
      if (tutorial_series_name) {
        shareUrl = `https://ilearnings.in/tutorial/${toSlug(
          tutorial_series_name
        )}`;
      }
    });
  }

  if (props.Read_more && Array.isArray(props.Read_more)) {
    props.Read_more.forEach(({ post_id, post_title }) => {
      if (post_id) {
        shareUrl = `https://ilearnings.in/read/${id}`;
        title = `${post_title}`;
      }
    });
  }

  // const imageUrl = `https://ilearnings.in/logo1.png`;
  // //const shareUrl = "https://www.barchart.com/";
  // const title = "send Title";
  // const shareUrl = "https://ilearnings.in/";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleCopy = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success("Link copied successfully!", {
          position: "top-center",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy the link!", {});
      });
  };

  return (
    <div className="dropdown">
      <span
        onClick={toggleDropdown}
        className="text-light"
        id="share-button"
        aria-haspopup="true"
        aria-expanded={isDropdownOpen}
        data-bs-toggle="dropdown"
      >
        <IoShareSocialOutline />
      </span>
      <div
        className={`dropdown-menu dropdown-menu-end ${
          isDropdownOpen ? "show" : ""
        }`}
        aria-labelledby="share-button"
      >
        <div className="dropdown-item" onClick={handleCopy}>
          <IoCopyOutline /> Copy Link
        </div>
        <div className="dropdown-item">
          <FacebookShareButton url={shareUrl} quote={title} hashtag="#Learning">
            <FaFacebook /> Facebook
          </FacebookShareButton>
        </div>
        <div className="dropdown-item">
          <TwitterShareButton url={shareUrl} title={title}>
            <FaTwitterSquare /> Twitter
          </TwitterShareButton>
        </div>
        <div className="dropdown-item">
          <LinkedinShareButton
            url={shareUrl}
            title={title}
            summary={title}
            source={imageUrl}
          >
            <FaLinkedin /> LinkedIn
          </LinkedinShareButton>
        </div>
        <div className="dropdown-item">
          <WhatsappShareButton url={shareUrl} title={title} separator={`\n`}>
            <FaWhatsappSquare /> WhatsApp
          </WhatsappShareButton>
        </div>
      </div>
    </div>
  );
};

export default ShareButton;
