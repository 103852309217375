import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { dataFormatter } from "../../constant/condition";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import Loader from "./Loader";
import Tags from "./Tags";
import NotFound from "./NotFound";
export default function Blogpost() {
  const [isLoading, setIsLoading] = useState(true);
  const [configuration, setConfiguration] = useState([]);
  const [data, setData] = useState([]);
  const { tag, id, year, month } = useParams();

  function demo2() {
    let url;

    if (tag) {
      url = `${config.API_BLOG}getdata?tag=${tag}`;
    } else if (id) {
      url = `${config.API_BLOG}getdata?key=${id}`;
    } else if (year && month) {
      url = `${config.API_BLOG}getdata?year=${year}&month=${month}`;
    } else {
      url = `${config.API_BLOG}getdata`;
    }

    fetch(url)
      .then((result) => result.json())
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching blog data:", err);
        setIsLoading(false);
      });
  }

  const toSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    demo2();
    fetchConfiguration();
  }, [id, tag, year, month]);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  // Filter posts with post_status === 1
  const filteredData = data.filter((item) => item.post_status === 1);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : filteredData.length === 0 ? (
        <div className="text-center">
          <NotFound />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Blogs</title>
            <meta name="title" content={metaTitle} />
            <meta name="keywords" content={metaKeywords} />
            <meta name="description" content={metaDescription} />
          </Helmet>

          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <center
                style={{
                  fontSize: "38px",
                  fontFamily: "sofia",
                }}
              >
                <b>
                  Bl<b style={{ color: "#173a9b" }}>ogs</b>
                </b>
                <hr className="w-25 mx-auto" />
              </center>
              <div className="row">
                {filteredData.map((item) => {
                  const newTypeDate = dataFormatter(item.published_date);
                  const limitedContent = (() => {
                    const parser = new DOMParser();
                    const plainText =
                      parser.parseFromString(item.post_content, "text/html")
                        .body.textContent || "";
                    const letterOnlyText = plainText.replace(/[^a-zA-Z ]/g, "");
                    let letterCount = 0;
                    let limitedText = "";
                    for (let i = 0; i < letterOnlyText.length; i++) {
                      if (/[a-zA-Z]/.test(letterOnlyText[i])) {
                        letterCount++;
                      }
                      if (letterCount <= 200) {
                        limitedText += letterOnlyText[i];
                      } else {
                        break;
                      }
                    }
                    return limitedText + (letterCount > 200 ? "..." : "");
                  })();

                  return (
                    <div
                      className="col-md-12 col-lg-12 col-xl-6 mt-2 mb-2 man1"
                      key={item.post_id}
                    >
                      <div className="card mt-2 p-3">
                        <Link
                          to={`/read/${toSlug(item.post_title)}`}
                          state={{ item }}
                          className="tutorial"
                          onClick={() => {
                            localStorage.setItem("post_id", item.post_id);
                          }}
                        >
                          <b className="heading_font">{item.post_title}</b>
                        </Link>
                        <div className="row">
                          <div className="col-sm-3  mt-2">
                            <center className="p-2">
                              <img
                                src={item.post_banner_b64}
                                style={{ width: "100px" }}
                                className="img-fluid"
                                alt={item.post_banner_b64}
                              />
                            </center>
                          </div>
                          <div
                            className="col-sm-9 mt-2 para_font_family"
                            style={{ textAlign: "justify", minHeight: "140px" }}
                          >
                            <span
                              style={{
                                color: "black",
                                textAlign: "justify",
                              }}
                              dangerouslySetInnerHTML={renderHTML(
                                limitedContent
                              )}
                            ></span>
                            <div className="row">
                              <Link
                                to={`/read/${toSlug(item.post_title)}`}
                                state={{ item }}
                                onClick={() => {
                                  localStorage.setItem("post_id", item.post_id);
                                }}
                                style={{
                                  height: "20px",
                                  lineHeight: "8px",
                                  fontSize: "15px",
                                  textDecoration: "none",
                                }}
                              >
                                <b
                                  style={{
                                    float: "right",
                                    marginTop: "10px",
                                  }}
                                >
                                  >Read More
                                </b>
                              </Link>
                            </div>
                          </div>
                          <div>
                            {/* <________________tags-------------------------> */}

                            <div className="container-fluid">
                              <div className="row">
                                <div className="tags-container">
                                  {item.tags.map((tag) => (
                                    <Link
                                      key={tag.tag_id}
                                      to={`/blog/tag/${tag.tag_id}`}
                                      state={{ item }}
                                      className="tag-link"
                                    >
                                      <button className="tag-button">
                                        {tag.tag_name} ({tag.posts_count})
                                      </button>
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {/* <Tags /> */}
                          </div>
                          <div className="col-sm-12">
                            <center>
                              <div
                                className="card-header"
                                style={{
                                  minHeight: "35px",
                                  lineHeight: "20px",
                                  background: "#173a9b",
                                }}
                              >
                                <div className="row text-center text-light para_font_family">
                                  <div className="col-sm-6">
                                    <p
                                      className="card-text"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <b> By:</b> {item.name}
                                    </p>
                                  </div>
                                  <div className="col-sm-6">
                                    <p className="card-text ">
                                      <b> On:</b> {newTypeDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <div className="side1">
            <Sidebar />
          </div>
        </>
      )}
    </div>
  );
}
