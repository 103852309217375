import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import { dataFormatter } from "../../constant/condition";
import { Link } from "react-router-dom";
import config from "../../config/Config";
import { Helmet } from "react-helmet";
import "react-country-state-city/dist/react-country-state-city.css";
import Loader from "./Loader"; // Import Loader
import Tags from "./Tags";
export default function Home() {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function fetchData() {
    fetch(`${config.API_BLOG}getdata`)
      .then((result) => {
        result.json().then((res) => {
          setData(res);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function fetchSeriesData() {
    fetch(`${config.API_TUTORIAL}getseries`)
      .then((result) => {
        result.json().then((res) => {
          setDatas(res);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  const toSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching configuration:", err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchData();
    fetchSeriesData();
    fetchConfiguration();
  }, []);

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  return (
    <div className="container-fluid mt-3 mb-4">
      <Helmet>
        <title>ilearnings</title>
        <meta name="title" content={metaTitle} />
        <meta name="keywords" content={metaKeywords} />
        <meta name="description" content={metaDescription} />
      </Helmet>

      {/* Display loader while data is loading */}
      {isLoading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          {/* Blog Section */}
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <center style={{ fontSize: "38px", fontFamily: "sofia" }}>
                <b>
                  Latest Blog <b style={{ color: "#173a9b" }}>Post</b>
                </b>
                <hr className="w-25 mx-auto" />
              </center>
              <div className="row">
                {data.map((item) => {
                  let newTypeDate = dataFormatter(item.published_date);
                  //word count
                  {
                    /* const limitedContent = (() => {
                    const parser = new DOMParser();
                    const plainText =
                      parser.parseFromString(item.post_content, "text/html")
                        .body.textContent || "";
                    return (
                      plainText.split(/\s+/).slice(0, 40).join(" ") +
                      (plainText.split(/\s+/).length > 40 ? "..." : "")
                    );
                  })(); */
                  }

                  //letter count
                  const limitedContent = (() => {
                    const parser = new DOMParser();
                    const plainText =
                      parser.parseFromString(item.post_content, "text/html")
                        .body.textContent || "";
                    const letterOnlyText = plainText.replace(/[^a-zA-Z ]/g, "");
                    let letterCount = 0;
                    let limitedText = "";
                    for (let i = 0; i < letterOnlyText.length; i++) {
                      if (/[a-zA-Z]/.test(letterOnlyText[i])) {
                        letterCount++;
                      }
                      if (letterCount <= 200) {
                        limitedText += letterOnlyText[i];
                      } else {
                      }
                    }
                    return limitedText + (letterCount > 200 ? "..." : "");
                  })();

                  return (
                    <div
                      className="col-md-12 col-lg-12 col-xl-6 mt-3 card_box "
                      key={item.post_id}
                    >
                      <div className="card mt-2 p-3">
                        <Link
                          to={`/read/${toSlug(item.post_title)}`}
                          state={{ item }}
                          onClick={() => {
                            localStorage.setItem("post_id", item.post_id);
                          }}
                          className="tutorial"
                        >
                          <b className="heading_font">{item.post_title}</b>
                        </Link>
                        <div className="row">
                          <div className="col-sm-3 mt-2">
                            <center className="p-2">
                              <img
                                src={item.post_banner_b64}
                                style={{ width: "100px" }}
                                className="img-fluid"
                                alt={item.post_banner_b64}
                              />
                            </center>
                          </div>
                          <div
                            className="col-sm-9 mt-2 para_font_family"
                            style={{ textAlign: "justify", minHeight: "140px" }}
                          >
                            <span
                              style={{
                                color: "black",
                                textAlign: "justify",
                              }}
                              dangerouslySetInnerHTML={renderHTML(
                                limitedContent
                              )}
                            ></span>

                            <div className="row">
                              <Link
                                to={`/read/${toSlug(item.post_title)}`}
                                state={{ item }}
                                onClick={() => {
                                  localStorage.setItem("post_id", item.post_id);
                                }}
                                style={{
                                  height: "20px",
                                  lineHeight: "8px",
                                  fontSize: "15px",
                                  textDecoration: "none",
                                }}
                              >
                                <b
                                  style={{
                                    float: "right",
                                    marginTop: "10px",
                                  }}
                                >
                                  >Read More
                                </b>
                              </Link>
                            </div>
                          </div>
                          {/* -----------------------------StartTags---------------------------------- */}
                          {/* <________________tags-------------------------> */}

                          <div className="container-fluid">
                            <div className="row">
                              <div className="tags-container">
                                {item.tags.map((tag) => (
                                  <Link
                                    key={tag.tag_id}
                                    to={`/blog/tag/${tag.tag_id}`}
                                    state={{ item }}
                                    className="tag-link"
                                  >
                                    <button className="tag-button">
                                      {tag.tag_name} ({tag.posts_count})
                                    </button>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </div>

                          {/* <Tags /> */}

                          {/* -----------------------------CloseTags---------------------------------- */}
                          <div className="col-sm-12">
                            <center>
                              <div
                                className="card-header"
                                style={{
                                  minHeight: "35px",
                                  lineHeight: "20px",
                                  background: "#173a9b",
                                }}
                              >
                                <div className="row text-center text-light para_font_family ">
                                  <div className="col-sm-6">
                                    <p
                                      className="card-text"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <b> By:</b> {item.name}
                                    </p>
                                  </div>
                                  <div className="col-sm-6">
                                    <p className="card-text ">
                                      <b> On:</b> {newTypeDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>

          {/* Tutorial Section */}
          <div className="row mt-4">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <center style={{ fontSize: "38px", fontFamily: "sofia" }}>
                <b>
                  Tutorial<b style={{ color: "#173a9b" }}> Series</b>
                </b>
                <hr className="w-25 mx-auto" />
              </center>
              <div className="row">
                {datas.map((item) => {
                  //word count
                  {
                    /* const limitedContentTutorials = (() => {
                    const parser = new DOMParser();
                    const plainText =
                      parser.parseFromString(
                        item.tutorial_series_description,
                        "text/html"
                      ).body.textContent || "";
                    return (
                      plainText.split(/\s+/).slice(0, 40).join(" ") +
                      (plainText.split(/\s+/).length > 40 ? "..." : "")
                    );
                  })(); */
                  }

                  //letter count
                  const limitedContentTutorials = (() => {
                    const parser = new DOMParser();
                    const plainText =
                      parser.parseFromString(
                        item.tutorial_series_description,
                        "text/html"
                      ).body.textContent || "";
                    const letterOnlyText = plainText.replace(/[^a-zA-Z ]/g, "");
                    let letterCount = 0;
                    let limitedText = "";
                    for (let i = 0; i < letterOnlyText.length; i++) {
                      if (/[a-zA-Z]/.test(letterOnlyText[i])) {
                        letterCount++;
                      }
                      if (letterCount <= 200) {
                        limitedText += letterOnlyText[i];
                      } else {
                        break;
                      }
                    }
                    return limitedText + (letterCount > 200 ? "..." : "");
                  })();

                  return (
                    <div
                      className="col-md-12 col-lg-12 col-xl-6 mt-3 card_box"
                      key={item.id}
                    >
                      <div className="card mt-2 p-3">
                        <Link
                          to={`/tutorial/${toSlug(item.tutorial_series_name)}`}
                          state={{ item }}
                          className="tutorial"
                        >
                          <b className="heading_font">
                            {item.tutorial_series_name}
                          </b>
                        </Link>
                        <div className="row">
                          <div className="col-sm-3">
                            <center className="p-2">
                              <img
                                src={item.tutorial_series_image_b64}
                                style={{ width: "100px" }}
                                className="img-fluid"
                                alt={item.tutorial_series_image_b64}
                              />
                            </center>
                          </div>
                          <div
                            className="col-sm-9 mt-2 para_font_family"
                            style={{ textAlign: "justify", minHeight: "140px" }}
                          >
                            <span
                              dangerouslySetInnerHTML={renderHTML(
                                limitedContentTutorials
                              )}
                            ></span>

                            <div className="row">
                              <Link
                                to={`/tutorial/${toSlug(
                                  item.tutorial_series_name
                                )}`}
                                state={{ item }}
                                style={{
                                  height: "20px",
                                  lineHeight: "8px",
                                  fontSize: "15px",
                                  textDecoration: "none",
                                }}
                              >
                                <b
                                  style={{ float: "right", marginTop: "10px" }}
                                >
                                  &gt; Show More
                                </b>
                              </Link>
                            </div>
                          </div>
                          <div className="col-sm-12 mt-2">
                            <center>
                              <div
                                className="card-header"
                                style={{
                                  minHeight: "35px",
                                  lineHeight: "20px",
                                  background: "#173a9b",
                                }}
                              >
                                <div className="row text-light para_font_family ">
                                  <div className="col-sm-6">
                                    <p
                                      className="card-text"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <b>#Lessons:</b> {item.lessons_count}
                                    </p>
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="card-text text-light"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <b>Technology:</b> {item.technologies}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </>
      )}
    </div>
  );
}
