import React, { useState, useEffect, useRef } from "react";
import "../Styles/Show.css";
import NotFound from "./NotFound";
import { FaFilePdf } from "react-icons/fa";
import config from "../../config/Config";
import { useNavigate, useParams } from "react-router-dom";
import LessonSidebar from "./LessonSidebar";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { decode } from "html-entities";
import Loader from "./Loader";
import $ from "jquery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { IoCopyOutline } from "react-icons/io5";
import { TiPen } from "react-icons/ti";
import { HiExternalLink } from "react-icons/hi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { PDFExport } from "@progress/kendo-react-pdf";
import ShareButton from "./ShareButton";

export default function Tutorial() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageName, setImageName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [configuration, setConfiguration] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  function demo() {
    fetch(`${config.API_TUTORIAL}lession`).then((result) => {
      result.json().then((res) => {
        setData(res);
        setIsLoading(false);
      });
    });
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    demo();
    setIsLoading(true);
    fetchConfiguration();
  }, [id]);

  $(document).ready(function () {
    $("figure img").each(function () {
      if ($(this).closest("table").length > 0) {
        // Skip this image
        return;
      }
      $(this).attr("width", "100%");
      $(this).attr("height", "auto");
    });
    $("figure img").on("click", function () {
      const imageName = $(this).attr("src");
      if (
        typeof setImageName === "function" &&
        typeof setIsOpen === "function"
      ) {
        setImageName(imageName);
        setIsOpen(true);
      } else {
        console.error("setImageName or setIsOpen is not defined.");
      }
    });
  });

  function renderDescription(description) {
    const preTagRegex = /<pre[^>]*>(?:<code[^>]*>)?(.*?)(?:<\/code>)?<\/pre>/gs;
    const figureTableRegex =
      /<figure[^>]*>[\s\S]*?<table[\s\S]*?<\/table>[\s\S]*?<\/figure>/g;
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/g;

    let parts = [];
    let lastIndex = 0;
    let match;
    while ((match = preTagRegex.exec(description)) !== null) {
      if (match.index > lastIndex) {
        const nonCodePart = description.slice(lastIndex, match.index);
        const processedPart = nonCodePart.replace(
          figureTableRegex,
          (figureWithTable) => {
            return figureWithTable.replace(
              /<table[^>]*>[\s\S]*?<\/table>/,
              (table) =>
                `<div style="overflow-x: auto; border: 1px solid #dddddd; margin: 8px 0;">${table}</div>`
            );
          }
        );
        const finalProcessedPart = processedPart.replace(
          tableRegex,
          (table) => {
            return table
              .replace(
                /<table[^>]*>/,
                '<table style="width: 100%; border-collapse: collapse;">'
              )
              .replace(
                /<td>/g,
                '<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">'
              );
          }
        );

        const updatedNonCodePart = parse(
          finalProcessedPart.replace(
            /<a /g,
            '<a target="_blank" rel="noopener noreferrer" '
          )
        );
        parts.push(updatedNonCodePart);
      }

      let decodedCode = decode(match[1].trim());
      decodedCode = decodedCode.replace(/<br\s*\/?>/g, "");
      decodedCode = decodedCode.replace(/<\/?strong>/g, "");
      const codeBlock = (
        <div
          className="code-container"
          key={match.index}
          style={{ position: "relative" }}
        >
          <SyntaxHighlighter
            language="javascript"
            style={oneDark}
            customStyle={{
              whiteSpace: "pre-wrap", // Wrap text instead of cutting it
              wordBreak: "break-word", // Break long words if necessary
              overflowX: "auto", // Enable horizontal scrolling if needed
            }}
          >
            {decodedCode}
          </SyntaxHighlighter>
          <div
            className="code-buttons p-1"
            style={{
              flexDirection: "row",
              gap: "4px",
              position: "absolute",
            }}
          >
            <button onClick={() => copyCode(decodedCode)} title="Copy Code">
              <IoCopyOutline />
            </button>
            <button
              onClick={(event) => openRow(decodedCode, event)}
              title="Simple Text"
            >
              <TiPen />
            </button>
            <button onClick={() => openExternal(decodedCode)} title="External">
              <HiExternalLink />
            </button>
          </div>
        </div>
      );
      parts.push(codeBlock);
      lastIndex = match.index + match[0].length;
    }
    if (lastIndex < description.length) {
      const remainingPart = description.slice(lastIndex);
      const processedRemainingPart = remainingPart.replace(
        figureTableRegex,
        (figureWithTable) => {
          return figureWithTable.replace(
            /<table[^>]*>[\s\S]*?<\/table>/,
            (table) =>
              `<div style="overflow-x: auto; border: 1px solid #dddddd; margin: 8px 0;">${table}</div>`
          );
        }
      );
      const finalProcessedRemainingPart = processedRemainingPart.replace(
        tableRegex,
        (table) => {
          return table
            .replace(
              /<table[^>]*>/,
              '<table style="width: 100%; border-collapse: collapse;">'
            )
            .replace(
              /<td>/g,
              '<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">'
            );
        }
      );

      const updatedRemainingPart = parse(
        finalProcessedRemainingPart.replace(
          /<a /g,
          '<a target="_blank" rel="noopener noreferrer"'
        )
      );
      parts.push(updatedRemainingPart);
    }

    return parts;
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied to clipboard!");
    });
  }

  function openExternal(code) {
    const win = window.open("", "_blank", "width=800,height=600,resizable=yes");
    const escapedCode = code
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    win.document.write(
      `<pre style="white-space: pre-wrap;">${escapedCode}</pre>`
    );
    win.document.close();
  }

  function openRow(code, event) {
    const button = event.target;
    const container = button.closest(".code-container");
    const preTag = container.querySelector("pre");

    if (!preTag) return;

    if (preTag.dataset.original) {
      preTag.innerHTML = preTag.dataset.original;
      preTag.removeAttribute("data-original");
    } else {
      preTag.dataset.original = preTag.innerHTML;
      preTag.textContent = code;
    }
  }

  const generatePDF = () => {
    contentRef.current.save();
    toast.success("PDF generated successfully!");
  };

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const toSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const filteredData = data.filter(
    (item) => item.lesson_id == id && item.status === 1
  );

  const goBack = () => {
    if (window.history.length > 2) {
      window.history.back();
    } else {
      navigate("/");
    }
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-1"></div>
          {filteredData.length > 0 ? (
            <div className="col-sm-10 mb-5">
              <div className="row">
                {filteredData.map((item) => {
                  return (
                    <div className="card mt-5 p-2" key={item.id}>
                      <Helmet>
                        <title>{item.lesson_title}</title>
                        <meta
                          name="title"
                          content={`${item.meta_title || metaTitle}`}
                        />
                        <meta
                          name="keywords"
                          content={`${item.meta_keyword || metaKeywords}`}
                        />
                        <meta
                          name="description"
                          content={`${
                            item.meta_description || metaDescription
                          }`}
                        />
                      </Helmet>

                      <div
                        className="card-header"
                        style={{
                          backgroundColor: "#173a9b",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#fff", cursor: "pointer" }}>
                          <span className="" onClick={goBack}>
                            <IoMdArrowRoundBack />
                          </span>
                        </span>
                        <span
                          className="card-title text-light mb-0 heading_font"
                          style={{ textAlign: "center", flex: 1 }}
                        >
                          {item.lesson_title}
                        </span>
                        <span style={{ color: "#fff", cursor: "pointer" }}>
                          <ShareButton Lesson_data={filteredData[0]} />
                        </span>
                        <span style={{ color: "#fff", margin: "5px" }}>
                          <FaFilePdf
                            onClick={generatePDF}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </div>

                      <PDFExport
                        paperSize="A4"
                        fileName={`${item.lesson_title}.pdf`}
                        ref={contentRef}
                      >
                        <div
                          className="para_font_family print_pdf"
                          style={{
                            padding: "10px",
                            fontSize: "18px",
                            margin: "10px",
                          }}
                        >
                          {renderDescription(
                            item?.lesson_content ||
                              "<p>No description available</p>"
                          )}
                        </div>
                      </PDFExport>
                    </div>
                  );
                })}
              </div>
              <div className="side2">
                <LessonSidebar />
              </div>
            </div>
          ) : (
            <div>
              <NotFound />
            </div>
          )}
          <div className="col-sm-1"></div>
        </div>
      )}
      <ToastContainer position="top-center" />
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={imageName}
            onCloseRequest={() => setIsOpen(false)}
            imageCaption="A beautiful forest"
          />
        )}
      </div>
    </div>
  );
}
