import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { dataFormatter } from "../../constant/condition";
import "../Styles/Home.css";
import NotFound from "./NotFound";
import config from "../../config/Config";
import { Helmet } from "react-helmet";

export default function SearchPage() {
  const location = useLocation();
  const [configuration, setConfiguration] = useState([]);
  const { data } = location.state || {};
  const [activeTab, setActiveTab] = useState("blog");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }

  useEffect(() => {
    fetchConfiguration();
  });

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  const hasBlogPosts = data?.blog_posts?.length > 0;
  const hasTutorialSeries = data?.tutorial_series?.length > 0;

  return (
    <div className="mb-4">
      {hasBlogPosts || hasTutorialSeries ? (
        <div>
          <center className="mt-2">
            <button
              className={`btn btn-primary ${
                activeTab === "blog" ? "active" : ""
              } m-2`}
              onClick={() => handleTabChange("blog")}
            >
              <b style={{ fontFamily: "sofia" }}>
                Blog ({data?.blog_posts?.length || 0})
              </b>
            </button>
            <button
              className={`btn btn-primary ${
                activeTab === "tutorial" ? "active" : ""
              }`}
              onClick={() => handleTabChange("tutorial")}
            >
              <b style={{ fontFamily: "sofia" }}>
                Tutorial ({data?.tutorial_series?.length || 0})
              </b>
            </button>
          </center>

          <hr className="w-25 mx-auto" />
          <div className="row">
            {activeTab === "blog" && hasBlogPosts ? (
              <div>
                {/****************************************** / Blog section *********************************************************************/}
                <div className="row">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-10">
                    <Helmet>
                      <meta name="title" content={metaTitle} />
                      <meta name="keywords" content={metaKeywords} />
                      <meta name="description" content={metaDescription} />
                    </Helmet>
                    <div className="row">
                      {data.blog_posts.map((blog) => {
                        const newTypeDate = dataFormatter(blog.published_date);

                        const limitedContent = (() => {
                          const parser = new DOMParser();
                          const plainText =
                            parser.parseFromString(
                              blog.post_content,
                              "text/html"
                            ).body.textContent || "";
                          const letterOnlyText = plainText.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          let letterCount = 0;
                          let limitedText = "";
                          for (let i = 0; i < letterOnlyText.length; i++) {
                            if (/[a-zA-Z]/.test(letterOnlyText[i])) {
                              letterCount++;
                            }
                            if (letterCount <= 200) {
                              limitedText += letterOnlyText[i];
                            } else {
                            }
                          }
                          return limitedText + (letterCount > 200 ? "..." : "");
                        })();

                        return (
                          <div
                            className="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3 card_box"
                            key={blog.post_id}
                          >
                            <div className="card mt-2 p-3">
                              <span className="blogs text-dark fs-4 heading_font ">
                                <b>{blog.post_title}</b>
                              </span>
                              <div className="row">
                                <div className="col-sm-3 mt-2">
                                  <center className="p-2">
                                    <img
                                      src={blog.post_banner_b64}
                                      style={{ width: "100px" }}
                                      className="img-fluid"
                                      alt="Blog banner"
                                    />
                                  </center>
                                </div>
                                <div
                                  className="col-sm-9 mt-2 para_font_family"
                                  style={{
                                    textAlign: "justify",
                                    minHeight: "140px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      textAlign: "justify",
                                    }}
                                    dangerouslySetInnerHTML={renderHTML(
                                      limitedContent
                                    )}
                                  ></span>
                                  <div className="row">
                                    <Link
                                      to={`/read/${toSlug(blog.post_title)}`}
                                      state={{ blog }}
                                      style={{
                                        height: "20px",
                                        lineHeight: "8px",
                                        fontSize: "15px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <b
                                        style={{
                                          float: "right",
                                          marginTop: "10px",
                                        }}
                                      >
                                        >Read More
                                      </b>
                                    </Link>
                                  </div>
                                </div>
                                {/* <________________tags-------------------------> */}

                                <div className="tags-container">
                                  {blog.tags.map((tag) => (
                                    <Link
                                      key={tag.tag_id}
                                      to={`/blog/tag/${tag.tag_id}`}
                                      state={{ blog }}
                                      className="tag-link"
                                    >
                                      <button className="tag-button">
                                        {tag.tag_name} ({tag.posts_count})
                                      </button>
                                    </Link>
                                  ))}
                                </div>

                                <div className="col-sm-12">
                                  <center>
                                    <div
                                      className="card-header"
                                      style={{
                                        minHeight: "35px",
                                        lineHeight: "20px",
                                        background: "#173a9b",
                                      }}
                                    >
                                      <div className="row text-center text-light para_font_family">
                                        <div className="col-sm-6">
                                          <p
                                            className="card-text"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b> By:</b> {blog.name}
                                          </p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="card-text ">
                                            <b> On:</b> {newTypeDate}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            ) : activeTab === "blog" ? (
              <center style={{ fontSize: "30px" }}>
                <b className="heading_font ">No blog posts found</b>
              </center>
            ) : null}

            {activeTab === "tutorial" && hasTutorialSeries ? (
              <div>
                {/****************************************** / Tutorial section *********************************************************************/}

                <div className="row ">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-10">
                    <div className="row">
                      {data.tutorial_series.map((tutorial) => {
                        {
                          /* const limitedContentTutorials =
                          tutorial.tutorial_series_description
                            .split(" ")
                            .slice(0, 30)
                            .join(" "); */
                        }

                        //letter count
                        const limitedContentTutorials = (() => {
                          const parser = new DOMParser();
                          const plainText =
                            parser.parseFromString(
                              tutorial.tutorial_series_description,
                              "text/html"
                            ).body.textContent || "";
                          const letterOnlyText = plainText.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          let letterCount = 0;
                          let limitedText = "";
                          for (let i = 0; i < letterOnlyText.length; i++) {
                            if (/[a-zA-Z]/.test(letterOnlyText[i])) {
                              letterCount++;
                            }
                            if (letterCount <= 200) {
                              limitedText += letterOnlyText[i];
                            } else {
                              break;
                            }
                          }
                          return limitedText + (letterCount > 200 ? "..." : "");
                        })();
                        return (
                          <div
                            className="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3"
                            key={tutorial.id}
                          >
                            <div className="card mt-2 p-3 para_font_family">
                              <Link
                                to={`/tutorial/${toSlug(
                                  tutorial.tutorial_series_name
                                )}`}
                                state={{ tutorial }}
                                className="tutorial"
                              >
                                <b className="heading_font">
                                  {tutorial.tutorial_series_name}
                                </b>
                              </Link>
                              <div className="row">
                                <div className="col-sm-3">
                                  <center className="p-2">
                                    <img
                                      src={tutorial.tutorial_series_image_b64}
                                      style={{ width: "100px" }}
                                      className="img-fluid"
                                      alt={tutorial.tutorial_series_image_b64}
                                    />
                                  </center>
                                </div>
                                <div
                                  className="col-sm-9 mt-2 para_font_family"
                                  style={{
                                    textAlign: "justify",
                                    minHeight: "140px",
                                  }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={renderHTML(
                                      limitedContentTutorials
                                    )}
                                  ></span>

                                  <div className="row">
                                    <Link
                                      to={`/tutorial/${toSlug(
                                        tutorial.tutorial_series_name
                                      )}`}
                                      state={{ tutorial }}
                                      style={{
                                        height: "20px",
                                        lineHeight: "8px",
                                        fontSize: "15px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <b
                                        style={{
                                          float: "right",
                                          marginTop: "10px",
                                        }}
                                      >
                                        &gt; Show More
                                      </b>
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-sm-12 mt-2">
                                  <center>
                                    <div
                                      className="card-header"
                                      style={{
                                        minHeight: "35px",
                                        lineHeight: "20px",
                                        background: "#173a9b",
                                      }}
                                    >
                                      <div className="row text-light">
                                        <div className="col-sm-6">
                                          <p
                                            className="card-text"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b>#Lessons:</b>{" "}
                                            {tutorial.lessons_count}
                                          </p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p
                                            className="card-text text-light"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b>Technology:</b>{" "}
                                            {tutorial.technologies}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            ) : activeTab === "tutorial" ? (
              <center style={{ fontSize: "30px" }}>
                <b className="heading_font ">No tutorials found</b>
              </center>
            ) : null}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
}
