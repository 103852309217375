import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "../Styles/Readmore.css";
import Comment from "./Comment";
import { FaFilePdf } from "react-icons/fa";
import config from "../../config/Config";
import NotFound from "./NotFound";
import { dataFormatter } from "../../constant/condition";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { decode } from "html-entities";
import { Helmet } from "react-helmet";
import Loader from "./Loader";
import $ from "jquery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { IoCopyOutline } from "react-icons/io5";
import { TiPen } from "react-icons/ti";
import { HiExternalLink } from "react-icons/hi";
import Tags from "./Tags";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import ShareButton from "./ShareButton";
import { PDFExport } from "@progress/kendo-react-pdf";
export default function Read() {
  const [isLoading, setIsLoading] = useState(true);
  const [imageName, setImageName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [configuration, setConfiguration] = useState([]);
  const [data, setData] = useState([]);
  const [comment_author_name, setComment_author_name] = useState("");
  const [comment_author_email, setComment_author_email] = useState("");
  const [comment_content, setComment_content] = useState("");
  const [comment_post_id, setComment_post_id] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  const imagedata = "https://ilearnings.in/logo1.png";
  const emailValidation = () => {
    return String(comment_author_email)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  function handal() {
    fetch(`${config.API_BLOG}comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment_author_name,
        comment_author_email,
        comment_content,
        comment_post_id,
      }),
    }).then((result) => {
      result.json().then((res) => {
        toast.success(res.message || "Comment submitted successfully!");
      });
    });
  }
  const handleSend = (e) => {
    e.preventDefault();
    if (comment_content === "") {
      setErrMsg("Message is required!");
    } else if (comment_author_name === "") {
      setErrMsg("Username  is required!");
    } else if (comment_author_email === "") {
      setErrMsg("Please provide your Email!");
    } else if (!emailValidation(comment_author_email)) {
      setErrMsg("Please provide a valid Email!");
    } else {
      setComment_author_name("");
      setComment_author_email("");
      setComment_content("");
      setComment_post_id("");

      handal();
    }
  };

  $(document).ready(function () {
    $("figure img").each(function () {
      if ($(this).closest("table").length > 0) {
        // Skip this image
        return;
      }
      $(this).attr("width", "100%");
      $(this).attr("height", "auto");
    });
    $("figure img").on("click", function () {
      const imageName = $(this).attr("src");
      if (
        typeof setImageName === "function" &&
        typeof setIsOpen === "function"
      ) {
        setImageName(imageName);
        setIsOpen(true);
      } else {
        console.error("setImageName or setIsOpen is not defined.");
      }
    });
  });

  function renderDescription(description) {
    const preTagRegex = /<pre[^>]*>(?:<code[^>]*>)?(.*?)(?:<\/code>)?<\/pre>/gs;
    const figureTableRegex =
      /<figure[^>]*>[\s\S]*?<table[\s\S]*?<\/table>[\s\S]*?<\/figure>/g;
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/g;

    let parts = [];
    let lastIndex = 0;
    let match;
    while ((match = preTagRegex.exec(description)) !== null) {
      if (match.index > lastIndex) {
        const nonCodePart = description.slice(lastIndex, match.index);
        const processedPart = nonCodePart.replace(
          figureTableRegex,
          (figureWithTable) => {
            return figureWithTable.replace(
              /<table[^>]*>[\s\S]*?<\/table>/,
              (table) =>
                `<div style="overflow-x: auto; border: 1px solid #dddddd; margin: 8px 0;">${table}</div>`
            );
          }
        );
        const finalProcessedPart = processedPart.replace(
          tableRegex,
          (table) => {
            return table
              .replace(
                /<table[^>]*>/,
                '<table style="width: 100%; border-collapse: collapse;">'
              )
              .replace(
                /<td>/g,
                '<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">'
              );
          }
        );

        const updatedNonCodePart = parse(
          finalProcessedPart.replace(
            /<a /g,
            '<a target="_blank" rel="noopener noreferrer" '
          )
        );
        parts.push(updatedNonCodePart);
      }

      let decodedCode = decode(match[1].trim());
      decodedCode = decodedCode.replace(/<br\s*\/?>/g, "");
      decodedCode = decodedCode.replace(/<\/?strong>/g, "");

      const codeBlock = (
        <div
          className="code-container"
          key={match.index}
          style={{ position: "relative" }}
        >
          <SyntaxHighlighter
            language="javascript"
            style={oneDark}
            customStyle={{
              whiteSpace: "pre-wrap", // Wrap text instead of cutting it
              wordBreak: "break-word", // Break long words if necessary
              overflowX: "auto", // Enable horizontal scrolling if needed
            }}
          >
            {decodedCode}
          </SyntaxHighlighter>
          <div
            className="code-buttons p-1"
            style={{
              flexDirection: "row",
              gap: "4px",
              position: "absolute",
            }}
          >
            <button onClick={() => copyCode(decodedCode)} title="Copy Code">
              <IoCopyOutline />
            </button>
            <button
              onClick={(event) => openRow(decodedCode, event)}
              title="Simple Text"
            >
              <TiPen />
            </button>
            <button onClick={() => openExternal(decodedCode)} title="External">
              <HiExternalLink />
            </button>
          </div>
        </div>
      );

      parts.push(codeBlock);
      lastIndex = match.index + match[0].length;
    }
    if (lastIndex < description.length) {
      const remainingPart = description.slice(lastIndex);
      const processedRemainingPart = remainingPart.replace(
        figureTableRegex,
        (figureWithTable) => {
          return figureWithTable.replace(
            /<table[^>]*>[\s\S]*?<\/table>/,
            (table) =>
              `<div style="overflow-x: auto; border: 1px solid #dddddd; margin: 8px 0;">${table}</div>`
          );
        }
      );
      const finalProcessedRemainingPart = processedRemainingPart.replace(
        tableRegex,
        (table) => {
          return table
            .replace(
              /<table[^>]*>/,
              '<table style="width: 100%; border-collapse: collapse;">'
            )
            .replace(
              /<td>/g,
              '<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">'
            );
        }
      );

      const updatedRemainingPart = parse(
        finalProcessedRemainingPart.replace(
          /<a /g,
          '<a target="_blank" rel="noopener noreferrer"'
        )
      );
      parts.push(updatedRemainingPart);
    }

    return parts;
  }

  function copyCode(code) {
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied to clipboard!");
    });
  }

  function openExternal(code) {
    const win = window.open("", "_blank", "width=800,height=600,resizable=yes");
    const escapedCode = code
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    win.document.write(
      `<pre style="white-space: pre-wrap;">${escapedCode}</pre>`
    );
    win.document.close();
  }

  function openRow(code, event) {
    const button = event.target;
    const container = button.closest(".code-container");
    const preTag = container.querySelector("pre");

    if (!preTag) return;

    if (preTag.dataset.original) {
      // Restore the original code block with syntax highlighting
      preTag.innerHTML = preTag.dataset.original;
      preTag.removeAttribute("data-original");
    } else {
      // Replace with plain text
      preTag.dataset.original = preTag.innerHTML;
      preTag.textContent = code; // Display plain text only
    }
  }
  function demo() {
    fetch(`${config.API_BLOG}readmore`).then((result) => {
      result.json().then((res) => {
        setData(res);
        setIsLoading(false);
      });
    });
  }

  function fetchConfiguration() {
    fetch(`${config.API_BLOG}configuration`)
      .then((result) => result.json())
      .then((res) => {
        setConfiguration(res);
      })
      .catch((err) => console.error("Error fetching configuration:", err));
  }
  const storedPostId = localStorage.getItem("post_id");
  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setComment_post_id(storedPostId);
    demo();
    fetchConfiguration();
  }, []);

  const generatePDF = () => {
    contentRef.current.save();
    toast.success("PDF generated successfully!");
  };

  const metaTitle = configuration[0]?.default_meta_title;
  const metaKeywords = configuration[0]?.default_meta_keywords;
  const metaDescription = configuration[0]?.default_meta_description;

  //window.localStorage.setItem("st", JSON.stringify(id));

  const toSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  // let filteredData = data.filter(
  //   (item) => item.post_title == id && item.post_status === 1
  // );

  let filteredData = data.filter(
    (item) => toSlug(item.post_title) === id && item.post_status === 1
  );

  const manish = () => {
    const From = document.querySelector("#read1");
    const From1 = document.querySelector("#read");

    From.style.display = "block";
    From1.style.display = "none";
  };

  // const goBack = () => {
  //   navigate("/");
  // };

  const goBack = () => {
    if (window.history.length > 2) {
      window.history.back();
    } else {
      navigate("/");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-1"></div>
        {isLoading ? (
          <div className="text-center mt-5">
            <Loader />
          </div>
        ) : (
          <div className="col-sm-10">
            {filteredData.length > 0 ? (
              filteredData.map((item) => {
                let newTypeDate = dataFormatter(item.published_date);

                return (
                  <div className="row" key={item.id}>
                    <Helmet>
                      <title>{item.post_title}</title>
                      <meta
                        name="title"
                        content={`${item.post_meta_title || metaTitle}`}
                      />
                      <meta
                        name="keywords"
                        content={`${item.post_meta_keywords || metaKeywords}`}
                      />
                      <meta
                        name="description"
                        content={`${
                          item.post_meta_description || metaDescription
                        }`}
                      />
                    </Helmet>
                    <div className="col-sm-12 card mt-5 mb-5">
                      <div className="row">
                        <div className="col-sm-12">
                          {/* <center>
                            <div className="background_Color mt-2 text-light p-2 heading_font">
                              <h3 className="card-title mb-0">
                                {item.post_title}
                              </h3>
                            </div>
                          </center> */}

                          <div
                            className="card-header mt-2 "
                            style={{
                              backgroundColor: "#173a9b",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ color: "#fff", cursor: "pointer" }}>
                              <span onClick={goBack}>
                                <IoMdArrowRoundBack />
                              </span>
                            </span>
                            <span
                              className="card-title text-light mb-0 heading_font"
                              style={{ textAlign: "center", flex: 1 }}
                            >
                              {item.post_title}
                            </span>
                            <span style={{ color: "#fff", cursor: "pointer" }}>
                              <ShareButton Read_more={filteredData} />
                            </span>
                            <span style={{ color: "#fff", margin: "5px" }}>
                              <FaFilePdf
                                onClick={generatePDF}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </div>
                          {/* <div
                            className="para_font_family print_pdf"
                            style={{ padding: "10px" }}
                          >
                            {renderDescription(
                              item?.post_content ||
                                "<p>No description available</p>"
                            )}
                          </div> */}

                          <PDFExport
                            paperSize="A4"
                            fileName={`${item.post_title}.pdf`}
                            ref={contentRef}
                          >
                            <div
                              className="para_font_family print_pdf"
                              style={{
                                padding: "10px",
                                fontSize: "18px",
                                margin: "10px",
                              }}
                            >
                              {renderDescription(
                                item?.post_content ||
                                  "<p>No description available</p>"
                              )}
                            </div>
                          </PDFExport>
                        </div>
                      </div>
                      {/* -----------------------------StartTags---------------------------------- */}
                      <div style={{ transform: "translateY(-5px)" }}>
                        {/* <________________tags-------------------------> */}

                        <div className="container-fluid">
                          <div className="row">
                            <div className="tags-container">
                              {item.tags.map((tag) => (
                                <Link
                                  key={tag.tag_id}
                                  to={`/blog/tag/${tag.tag_id}`}
                                  state={{ item }}
                                  className="tag-link"
                                >
                                  <button className="tag-button">
                                    {tag.tag_name} ({tag.posts_count})
                                  </button>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/* <Tags /> */}
                      </div>
                      {/* -----------------------------CloseTags---------------------------------- */}
                      <div className="col-sm-12 background_Color para_font_family">
                        <center>
                          <div
                            className="card-header"
                            style={{ minHeight: "40px" }}
                          >
                            <div className="row text-center text-light">
                              <div className="col-sm-6">
                                <p
                                  className="card-text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b> By:</b> {item.name}
                                </p>
                              </div>
                              <div className="col-sm-6">
                                <p className="card-text">
                                  <b> On:</b> {newTypeDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                      <hr />
                      <div className="row mt-4">
                        <div className="col-sm-4">
                          <Comment />
                        </div>
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-2">
                                  <center
                                    style={{
                                      fontSize: "50px",
                                      color: "#173a9b",
                                    }}
                                  >
                                    <FaUserCircle />
                                  </center>
                                </div>
                                <div className="col-sm-6 col-md-10 col-lg-6">
                                  <div onClick={manish} id="read">
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="heading_font"
                                    >
                                      Be the First to Comment!.....
                                    </span>
                                  </div>
                                  <form>
                                    <div id="read1">
                                      <div className="row mb-3 para_font_family">
                                        {errMsg && (
                                          <p style={{ color: "red" }}>
                                            {errMsg}
                                          </p>
                                        )}
                                        {successMsg && (
                                          <p className="py-3 bg-gradient text-dark text-center fs-5">
                                            {successMsg}
                                          </p>
                                        )}
                                        <div className="mb-3 text-dark">
                                          <textarea
                                            onChange={(e) =>
                                              setComment_content(e.target.value)
                                            }
                                            value={comment_content}
                                            className={`form-control ${
                                              errMsg ===
                                                "Comment is required!" &&
                                              "is-invalid"
                                            }`}
                                            id="comment"
                                            placeholder="Be the First to Comment!....."
                                          ></textarea>
                                        </div>
                                        <div className="mb-3 text-dark">
                                          <input
                                            onChange={(e) =>
                                              setComment_author_name(
                                                e.target.value
                                              )
                                            }
                                            value={comment_author_name}
                                            className={`form-control ${
                                              errMsg === "name is required!" &&
                                              "is-invalid"
                                            }`}
                                            type="text"
                                            id="name"
                                            placeholder="Name *"
                                          />
                                        </div>
                                        <div className="mb-3 text-dark">
                                          <input
                                            onChange={(e) =>
                                              setComment_author_email(
                                                e.target.value
                                              )
                                            }
                                            value={comment_author_email}
                                            className={`form-control ${
                                              errMsg ===
                                                "Please give your Email!" &&
                                              "is-invalid"
                                            }`}
                                            type="email"
                                            id="email"
                                            placeholder="Email *"
                                          />
                                        </div>
                                        <div className="text-light">
                                          <button
                                            onClick={handleSend}
                                            className="background_Color p-2 text-light"
                                          >
                                            Send Message
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <NotFound />
              </div>
            )}
          </div>
        )}

        <div className="col-sm-1"></div>
      </div>
      <ToastContainer position="top-center" />
      <div>
        {/* Lightbox */}
        {isOpen && (
          <Lightbox
            mainSrc={imageName}
            onCloseRequest={() => setIsOpen(false)}
            imageCaption="A beautiful forest"
          />
        )}
      </div>
    </div>
  );
}
