// import React, { useState, useEffect } from "react";
// import PopupExample from "./PopupExample";
// import { useParams } from "react-router-dom";
// import config from "../../config/Config";
// import { BsChevronDown, BsChevronUp } from "react-icons/bs";

// export default function Comment() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [visibleReplies, setVisibleReplies] = useState(null);
//   const { id } = useParams();

//   function fetchComments() {
//     fetch(`${config.API_BLOG}comments`)
//       .then((response) => response.json())
//       .then((res) => {
//         setData(res);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       });
//   }

//   useEffect(() => {
//     fetchComments();
//   }, []);

//   const filteredData = data.filter(
//     (item) => item.comment_status === 1 && item.comment_post_id == id
//   );

//   const handleShowReplies = (commentId) => {
//     setVisibleReplies(visibleReplies === commentId ? null : commentId);
//   };

//   const getReplies = (commentId) =>
//     data.filter(
//       (item) => item.comment_status === 1 && item.comment_parent == commentId
//     );

//   console.log(getReplies);

//   return (
//     <div>
//       <center className="heading_font">
//         <span className="fs-2">Comments</span>
//         <div>
//           {loading ? (
//             <p>Loading...</p>
//           ) : filteredData.length === 0 ? (
//             <p>No comments available.</p>
//           ) : (
//             <>
//               {filteredData
//                 .filter((item) => item.comment_parent === null)
//                 .map((item) => (
//                   <div key={item.id} className="fs-6">
//                     <div>Name: {item.comment_author_name}</div>
//                     <span>Comment: {item.comment_content}</span>
//                     <PopupExample m={item.comment_id} />
//                     <span style={{ zIndex: 1 }}>
//                       {getReplies(item.comment_id).length > 0 && (
//                         <button
//                           style={{ transform: "translateY(-20px)" }}
//                           onClick={() => handleShowReplies(item.comment_id)}
//                           className="btn btn-link"
//                         >
//                           {visibleReplies == item.comment_id ? (
//                             <span style={{ fontSize: "14px" }}>
//                               <BsChevronUp />
//                               {getReplies(item.comment_id).length}
//                               &nbsp;replies
//                             </span>
//                           ) : (
//                             <span style={{ fontSize: "14px" }}>
//                               <BsChevronDown />
//                               {getReplies(item.comment_id).length}&nbsp;replies
//                             </span>
//                           )}
//                         </button>
//                       )}
//                     </span>
//                     {visibleReplies == item.comment_id && (
//                       <div style={{ marginLeft: "20px" }}>
//                         {getReplies(item.comment_id).map((reply) => (
//                           <div
//                             key={reply.comment_id}
//                             style={{
//                               marginLeft: "20px",
//                               fontSize: "14px",
//                               transform: "translateY(-20px)",
//                             }}
//                           >
//                             <div>Name: {reply.comment_author_name}</div>
//                             <span>Comment: {reply.comment_content}</span>
//                           </div>
//                         ))}
//                       </div>
//                     )}
//                   </div>
//                 ))}
//             </>
//           )}
//         </div>
//       </center>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import PopupExample from "./PopupExample";
import { useParams } from "react-router-dom";
import config from "../../config/Config";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

export default function Comment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleReplies, setVisibleReplies] = useState({});
  //const { id } = useParams();
  const storedPostId = localStorage.getItem("post_id");
  // Fetch comments from the API
  function fetchComments() {
    fetch(`${config.API_BLOG}comments`)
      .then((response) => response.json())
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchComments();
  }, []);

  // Filter comments for the current post
  const filteredData = data.filter(
    (item) => item.comment_status === 1 && item.comment_post_id == storedPostId
  );

  // Handle showing replies for a comment
  const handleShowReplies = (commentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  // Get replies for a specific comment
  const getReplies = (commentId) =>
    data.filter(
      (item) => item.comment_status === 1 && item.comment_parent == commentId
    );

  // Recursive component to render comments and their replies
  const renderComments = (comments) =>
    comments.map((comment) => (
      <div
        key={comment.comment_id}
        className="fs-6"
        style={{ marginLeft: "20px" }}
      >
        <div style={{ fontSize: "16px" }} className="heading_font">
          @{comment.comment_author_name}
        </div>
        <span style={{ fontSize: "14px", fontFamily: "sofia" }}>
          {comment.comment_content}
        </span>
        <PopupExample m={comment.comment_id} />
        {getReplies(comment.comment_id).length > 0 && (
          <p
            onClick={() => handleShowReplies(comment.comment_id)}
            className="replies_button"
          >
            {visibleReplies[comment.comment_id] ? (
              <span style={{ fontSize: "15px", fontFamily: "sofia" }}>
                <BsChevronUp className="me-1" />
                {getReplies(comment.comment_id).length} replies
              </span>
            ) : (
              <span style={{ fontSize: "15px", fontFamily: "sofia" }}>
                <BsChevronDown className="me-1" />
                {getReplies(comment.comment_id).length} replies
              </span>
            )}
          </p>
        )}
        {visibleReplies[comment.comment_id] && (
          <div>{renderComments(getReplies(comment.comment_id))}</div>
        )}
      </div>
    ));

  return (
    <div>
      <span className="fs-2 heading_font me-2">Comments:-</span>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : filteredData.length === 0 ? (
          <p>No comments available.</p>
        ) : (
          <div>
            {renderComments(
              filteredData.filter((comment) => comment.comment_parent === null)
            )}
          </div>
        )}
      </div>
    </div>
  );
}
