import React, { useState, useEffect } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import config from "../../config/Config";
import { Link } from "react-router-dom";
import "../Styles/Popup.css";
import { FaCommentDots } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const PopupExample = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [comment_author_name, setComment_author_name] = useState("");
  const [comment_author_email, setComment_author_email] = useState("");
  const [comment_content, setComment_content] = useState("");
  const [contact_number, setContact_number] = useState("");
  const [comment_post_id, setComment_post_id] = useState(""); // Initialize with an empty string
  const [comment_parent, setComment_parent] = useState(""); // Initialize with an empty string
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const emailValidation = () => {
    return String(comment_author_email)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  useEffect(() => {
    const storedPostId = localStorage.getItem("post_id");
    setComment_post_id(storedPostId);
  }, []);

  useEffect(() => {
    setComment_parent(props.m);
  }, [props.m]);

  function Click() {
    fetch(`${config.API_BLOG}comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment_author_name,
        comment_author_email,
        comment_content,
        comment_post_id,
        comment_parent,
      }),
    }).then((result) => {
      result.json().then((res) => {
        toast.success(res.message || "Form submitted successfully!");
        setIsOpen(false);
      });
    });
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (comment_author_name === "") {
      setErrMsg("Username is required!");
    } else if (comment_author_email === "") {
      setErrMsg("Please give your Email!");
    } else if (!emailValidation(comment_author_email)) {
      setErrMsg("Give a valid Email!");
    } else if (comment_content === "") {
      setErrMsg("Message is required!");
    } else if (comment_post_id === "") {
      setErrMsg("Message is required!");
    } else if (comment_parent === "") {
      setErrMsg("Message is required!");
    } else {
      setComment_author_name("");
      setComment_author_email("");
      setComment_content("");
      setComment_post_id("");
      setComment_parent("");

      Click();
    }
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  return (
    <div style={{ zIndex: 99999 }}>
      <Link
        onClick={openPopup}
        style={{
          textDecoration: "none",
          fontFamily: "sofia",
          marginLeft: "150px",
          fontSize: "13px",
        }}
      >
        {/* <FaCommentDots /> */}
        Reply
      </Link>
      {isOpen && (
        <div className="popup-container">
          <div className="popup-background">
            <button onClick={closeModal} className="pop">
              <RiCloseCircleFill className="close-icon" />
            </button>
            <div className="popup-content">
              <div>
                <div className="content mt-4">
                  <form>
                    {errMsg && (
                      <p className="py-3" style={{ color: "red" }}>
                        {errMsg}
                      </p>
                    )}
                    {successMsg && <p>{successMsg}</p>}
                    <div className="mb-3 text-dark">
                      <input
                        onChange={(e) => setComment_author_name(e.target.value)}
                        value={comment_author_name}
                        className={`form-control ${
                          errMsg === "Username is required!" && "is-invalid"
                        }`}
                        type="text"
                        id="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="mb-3 text-dark">
                      <input
                        onChange={(e) =>
                          setComment_author_email(e.target.value)
                        }
                        value={comment_author_email}
                        className={`form-control ${
                          errMsg === "Please give your Email!" && "is-invalid"
                        }`}
                        type="email"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="mb-3 text-dark">
                      <textarea
                        onChange={(e) => setComment_content(e.target.value)}
                        value={comment_content}
                        className={`form-control ${
                          errMsg === "Message is required!" && "is-invalid"
                        }`}
                        id="comment"
                        placeholder="Comment"
                      ></textarea>
                    </div>
                    <div className="mb-3 text-dark">
                      <input
                        hidden
                        value={comment_post_id}
                        className={`form-control ${
                          errMsg === "post_id is required!" && "is-invalid"
                        }`}
                        type="text"
                        id="contact"
                        placeholder="post_id*"
                      />
                    </div>
                    <div className="mb-3 text-dark">
                      <input
                        hidden
                        value={comment_parent}
                        className={`form-control ${
                          errMsg === "Comment Parent is required!" &&
                          "is-invalid"
                        }`}
                        type="text"
                        id="contact"
                        placeholder="Comment Parent*"
                      />
                    </div>
                    <div className=" text-light mt-2">
                      <button
                        style={{ height: "40px" }}
                        onClick={handleSend}
                        className="background_Color w-100 text-light"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default PopupExample;
